<template>
  <div class="editor">
        <Editor v-model="myValue" :init="init" :disabled="disabled"/>
    </div>
</template>

<script>
import tinymce from "tinymce"
import Editor from "@tinymce/tinymce-vue"
import "tinymce/icons/default"
import "tinymce/themes/silver"
import "tinymce/plugins/paste"
import "tinymce/plugins/image"
import "tinymce/plugins/link"
import "tinymce/plugins/code"
import "tinymce/plugins/table"
import "tinymce/plugins/lists"
import "tinymce/plugins/wordcount"

export default {
    name: "MyTinymce",
    components: {
        Editor
    },
    props: {
        value:{
            type:String,
            default:''
        },
        ossOptions:{
            type:Object,
            default:()=>{},
        },
        disabled: Boolean
    },
    data(){
        return {
            myValue: this.value,
            init: {
                language_url: '/static/tinymce/langs/zh_CN.js', //如果语言包不存在，指定一个语言包路径
                language: 'zh_CN', //语言
                skin_url: '/static/tinymce/skins/ui/oxide', //如果主题不存在，指定一个主题路径
                content_css: '/static/tinymce/skins/ui/oxide/content.css',
                height: '300px',
                width: '100%',
                menubar: false, //菜单栏
                browser_spellcheck: true, //拼写检查
                branding: false, //去水印
                elementpath: true, //禁用编辑器底部的状态栏
                statusbar: false, //隐藏编辑器底部的状态栏
                paste_data_images: true, //允许粘贴图像
                themes: 'silver', //主题
                // zIndex: 1101,
                plugins: ['paste', 'code', 'table', 'image', 'lists'],
                toolbar: 'bold italic underline strikethrough | fontsizeselect | forecolor backcolor | alignleft aligncenter alignright alignjustify|bullist numlist |outdent indent blockquote | undo redo | lists image media table code | removeformat',
                fontsize_formats: "10px 12px 14px 16px 18px 24px 36px",
                images_upload_handler: (blobInfo, success, failure) => {
                    this.handleImgUpload(blobInfo, success, failure)
                }
            },
        }
    },
    mounted() {
    },
    watch:{
        value(newValue) {
            this.myValue = newValue;
        },
        myValue(newValue) {
            this.$emit('input', newValue);
        }
    },
    methods: {
        // 用于上传图片的，后端需要提供好上传接口
        handleImgUpload(blobInfo, success, failure) {
            var file = blobInfo.blob();
            if(!file) return;
            this.$file.ossUpload({
                file,
                uploadOptions: this.ossOptions
            }).then(data=>{
                success(data.url);
            }).catch(()=>{
                failure('图片上传失败');
            });

        }
    }
}
</script>
<style scoped>
    .editor{
        max-width: 700px;
    }
</style>